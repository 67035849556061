const Newsletter = {

    main : null,
    form : null,
    errors : {
        invalid_ip : '*IP de origem inválido',
        database_insert : '*Erro ao gravar dados',
        token : 'Desculpe, houve uma falha na solicitação. Por favor, recarregue a página e tente novamente.'
    },
    fields : {
        email : {
            self : null,
            wrapper : null,
            message : null,
            errors : {
                required : '*O e-mail é obrigatório',
                invalid_domain : '*Domínio de email digitado inválido',
                invalid_format : '*E-mail digitado inválido',
                exists : '*O e-mail já foi cadastrado'
            }
        },
        accept : {
            self : null,
            wrapper : null,
            message : null,
            errors : {
                required : '*Você precisa estar de acordo com os termos para seguir!'
            }
        }
    },
    recaptcha : false,
    captchaField : null,
    captchaBox : null,
    button : null,

    init : function(){

        // Recaptcha configure
        Newsletter.captchaConfigure();

        // Main
        Newsletter.main = document.querySelector( 'section.newsletter' );

        // Form
        Newsletter.form = document.querySelector( 'form' );

        // Email
        Newsletter.fields.email.wrapper = Newsletter.form.querySelector( '.field.email' );
        Newsletter.fields.email.message = Newsletter.fields.email.wrapper.querySelector( '.error-message' );
        Newsletter.fields.email.self = Newsletter.fields.email.wrapper.querySelector( 'input[name="e-mail"]' );

        // Accept
        Newsletter.fields.accept.wrapper = Newsletter.form.querySelector( '.field.accept' );
        Newsletter.fields.accept.message = Newsletter.fields.accept.wrapper.querySelector( '.error-message' );
        Newsletter.fields.accept.self = Newsletter.fields.accept.wrapper.querySelector( 'input[name="accept"]' );
        
        // Button
        Newsletter.button = Newsletter.form.querySelector( 'button' );
        Newsletter.button.setAttribute( 'type', 'button' );

        // Binders
        Newsletter.fields.email.self.addEventListener( 'keyup', () => {
            Newsletter.checkEmailValue();
        });
        Newsletter.fields.accept.self.addEventListener( 'change', () => {
            if( Newsletter.fields.accept.self.checked === true ){
                Newsletter.removeFieldError( 'accept' );
            }
        });
        Newsletter.form.addEventListener( 'submit', event => {
            event.preventDefault();
            Newsletter.bindSubmit();
        });
        Newsletter.button.addEventListener( 'click', event => {
            event.preventDefault();
            Newsletter.bindSubmit();
        });
        
        // Check email value
        Newsletter.checkEmailValue();

    },

    bindSubmit : async function(){

        // Clear errors
        Newsletter.removeFieldError( 'email' );
        Newsletter.removeFieldError( 'accept' );

        // Error flag
        let formValid = true;

        // Validate e-mail
        let email_valid = true;
        if( Newsletter.fields.email.self.value === '' ){
            Newsletter.setFieldError( 'email', 'required' );
            formValid = false;
        }
        else if( Newsletter.validateEmail() === false ){
            Newsletter.setFieldError( 'email', 'invalid_format' );
            formValid = false;
        }

        // Accept
        if( Newsletter.fields.accept.self.checked === false ){
            Newsletter.setFieldError( 'accept', 'required' );
            formValid = false;
        }

        // If valid, send data
        if( formValid ){
            grecaptcha.execute( Newsletter.recaptcha );
        }
        
    },
    
    captchaConfigure : function( data ){

        Newsletter.captchaField = document.querySelector( 'input#recaptcha-news' );
        Newsletter.captchaBox = document.querySelector( '#recaptcha-news-box' );
        
        if( Newsletter.captchaField ){
        
            const recaptchaKey = Newsletter.captchaField.dataset.recaptchaKey;

            grecaptcha.ready(function(){
        
                Newsletter.recaptcha = grecaptcha.render(
                    Newsletter.captchaBox, 
                    {
                        'sitekey' : recaptchaKey,
                        'size' : 'invisible',
                        'callback' : function( token ){
                            Newsletter.captchaCallback( token );
                        }
                    }
                );
        
            });
        
        }

    },

    // Captcha callback
    captchaCallback : function( token ){

        // Insert captcha token
        Newsletter.captchaField.value = token;            
        
        // Submit form
        Newsletter.sendForm();
    
    },

    sendForm : async function( token ){

        $.ajax({
            url: "./submit-news.php",
            type : 'POST',
            dataType : 'JSON',
            data : {
                email : Newsletter.fields.email.self.value,
                accept : Newsletter.fields.accept.self.checked === true ? 'sim' : 'nao',
                token : Newsletter.captchaField.value
            },
            beforeSend: function( xhr ) {
                xhr.overrideMimeType( "text/plain; charset=utf-8" );
            }
        })
        .done( response => {
            Newsletter.receiveRequest( response );            
        })
        .fail( ( jqXHR, textStatus, errorThrown ) => {
            console.info( "jqXHR" );
            console.log( jqXHR );
        })
        .always( () => {
            console.log( "complete" );
        });

    },

    receiveRequest : function( response ){

        if( response.status === 'error' ){

            if( response.field !== undefined ){
                Newsletter.setFieldError( response.field, response.type );
            }
            
        }
        else {

            Newsletter.main.classList.add( 'success' );
            setTimeout( () => {
                Newsletter.form.reset();
                Newsletter.main.classList.remove( 'success' );
            }, 5000 );

        }
        
    },

    checkEmailValue : function(){

        // Envelope
        if( Newsletter.fields.email.self.value.length >= 4 ){
            Newsletter.form.classList.add( 'active' );
            Newsletter.button.disabled = false;
        }
        else {
            Newsletter.form.classList.remove( 'active' );
            Newsletter.button.disabled = true;
        }

        // Valid email
        if( Newsletter.fields.email.self.value === '' || Newsletter.validateEmail() ){
            Newsletter.removeFieldError( 'email' );
        }

    },

    setFieldError : function( field, error ){
        Newsletter.fields[ field ].message.innerHTML = Newsletter.fields[ field ].errors[ error ];
        Newsletter.fields[ field ].wrapper.classList.add( 'error' );
    },

    removeFieldError : function( field ){
        Newsletter.fields[ field ].wrapper.classList.remove( 'error' );
    },

    validateEmail : function(){
        const mailValue = String( Newsletter.fields.email.self.value.toLowerCase() );
        return mailValue.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null;
    }

};

window.addEventListener( 'DOMContentLoaded', Newsletter.init );
