var PKShare;

(function($){

	PKShare = {

		init : function(){
			PKShare.bindModal();
			PKShare.bindLinks();
		},

		bindLinks : function(){

			const _body = $( 'body' );

			// Links
			const widgets = document.querySelectorAll( '.pikishare' );
			if( widgets.length ){
				widgets.forEach( widget => {

					widget.classList.add( navigator.share ? 'mobile' : 'desktop' );

					const links = widget.querySelectorAll( 'a' );
					links.forEach( link => {

						link.target = '_blank';
						link.rel = 'noreferrer noopener';

						link.addEventListener( 'click', event => {

							// Internal popuos
							if( !link.classList.contains( 'whatsapp' ) && !link.classList.contains( 'email' ) ){
								event.preventDefault();
								window.open( link.href, link.title, "width=500,height=500" );
							}
							
							// Count share count
							PKShare.sendShareCount( link.dataset.type );
						
						});
					
					});
					
				});
			}
		
		},
		
		bindModal : function(){

            const 
                modalShare = document.querySelector( '.modal-share' ),
                copyField = document.querySelector( 'div[data-type="address"]' ),
                copyBox = document.querySelector( '.copy-box' );
            ;
            let timeCopy = false;

            const closeModal = function(){
                modalShare.classList.remove( 'opened' );
                Navigation.unlockScroll();
            };
            const openModal = function(){
                modalShare.classList.add( 'opened' );
                Navigation.lockScroll();
            };

            // Bind share click buttons
            const openButtons = document.querySelectorAll( '[data-action="open-news-form"]' );
            openButtons.forEach( button => {
                button.addEventListener( 'click', event => {
                	openModal();
                });
            });

            // Close modal
            modalShare.addEventListener( 'click', event => {
                if( event.target.classList.contains( 'modal-share' ) ){
                    closeModal();
                }
            });
            document.querySelector( '[data-action="close-share"]' ).addEventListener( 'click', event => {
                event.preventDefault();
                closeModal();
            });

            // Copy url button
            document.querySelector( '[data-action="copy-address"]' ).addEventListener( 'click', event => {

            	// Count share count
				PKShare.sendShareCount( 'urlcopy' );
            
                event.preventDefault();
                PKShare.copyDivToClipboard( copyField );
                copyBox.classList.add( 'copied' );

                if( timeCopy ) clearTimeout( timeCopy );
                timeCopy = setTimeout( () => {
                    copyBox.classList.remove( 'copied' );
                }, 3000 );
            
            });

        },

        copyDivToClipboard : function( element ) {
            var range = document.createRange();
            range.selectNode( element );
            window.getSelection().removeAllRanges();
            window.getSelection().addRange( range );
            document.execCommand( 'copy' );
            window.getSelection().removeAllRanges();
        },

		sendShareCount : function( type ){
			$.ajax({
	            url: "./keep-share.php",
	            type : 'POST',
	            dataType : 'JSON',
	            data : {
	                type : type
	            },
	            beforeSend: function( xhr ) {
	                xhr.overrideMimeType( "text/plain; charset=utf-8" );
	            }
	        });
		},

		// Mobile share
		bindBMobileButtonClick : function(){
		
			navigator
				.share({
					title: document.title,
					text: "Hello World",
					url: window.location.href
				})
				.then( function(){
					console.log( 'Successful share' ) 
				})
				.catch(function( error ){
					console.log( 'Error sharing:', error )
				})
			;
		
		}

	};

	window.addEventListener( 'DOMContentLoaded', () => {
		PKShare.init();	
	});
	
})(jQuery);
