var animaItems = {

	init : () => {

		const items = document.querySelectorAll( '.move-item' );
		if( !items.length ) return true;

		items.forEach( item => {
			animaItems.configureItem( item );

		});

	},

	configureItem : ( item ) => {

		const data = {
			self : item,
			target : item.querySelector( '.move-target' ),
			percurso : 135
		};

		data.self.addEventListener( 
			'mousemove',
			event => { 
				animaItems.parallax( data, event ) 
			}
		);
		
	},

	parallax : ( data, event ) => {

		// Eixo X
		const
			percX = 3,
			moved = ( event.pageX * 100 / window.innerWidth * 2 ),
			posX = ( percX * ( moved / 100 ) )
		;

		if( !data.self.classList.contains( 'move-x' ) ){

		    // Eixo Y
		    const 
		    	postTop = event.pageY - data.self.offsetTop,
		    	posY = ( data.self.offsetHeight - postTop * 2 ) / 90;
		    ;

		    // Do move
		    data.target.style.transform = `translateX(-${posX}%) translateY(${posY}px)`;

		}
		else {

			// Do move
		    data.target.style.transform = `translateX(-${posX}%)`;

		}
	
	}

}
document.addEventListener( 'DOMContentLoaded', () => {
	animaItems.init();
});