(function($){

	var Videos = {

		timeAPI : false,

		init : function(){

			$( '.video-container button' ).on( 'click', function(e){

	            // Init Vídeo
	            this.APIWait(function(){

	                // Inicia o vídeo
	                data.player = new YT.Player(
	                    'video', 
	                    {
	                        width: '100%',
	                        height: '100%',
	                        videoId: 'RVdycGxMZH8',
	                        playerVars: { 
	                            autoplay : false, 
	                            showinfo : false, 
	                            rel : false 
	                        },
	                        events: {
	                            'onStateChange': function( e ){
	                                Videos.onPlayerStateChange( e, data );
	                            }
	                        }
	                    }
	                );
	            });

			});

		},

        // Waiting youtube API
        APIWait : function( callback ){
            if( typeof( YT ) === 'undefined' || typeof( YT.Player ) === 'undefined' ){
                if( Videos.timeAPI ) clearTimeout( Videos.timeAPI );
                Videos.timeAPI = setTimeout(function(){
                    Videos.APIWait( callback );
                }, 50);
            }
            else {
                clearTimeout( Videos.timeAPI );
                callback();
            }
        }
        
	}

	//window.addEventListener( 'DOMContentLoaded', Videos.init )

})(jQuery);	
