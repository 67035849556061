const Enquete = {

    main : null,
    quizz : null,
    form : null,
    result : null,
    fields : null,
    recaptcha : false,
    captchaField : null,
    captchaBox : null,
    button : null,

    init : function(){

        // Recaptcha configure
        Enquete.captchaConfigure();

        // Main
        Enquete.main = document.querySelector( 'section#quizz' );

        // Quizz
        Enquete.quizz = Enquete.main.querySelector( '.quizz' );
        Enquete.form = Enquete.quizz.querySelector( 'form' );

        // Result
        Enquete.result = Enquete.main.querySelector( '.result' );

        // Button
        Enquete.button = Enquete.form.querySelector( 'button' );
        Enquete.button.setAttribute( 'type', 'button' );

        // Fields
        Enquete.fields = Enquete.form.querySelectorAll( 'input[type="radio"]' );
        Enquete.fields.forEach( field => {
            field.addEventListener( 'change', () => {
                Enquete.checkVoto();
            });
        });
        Enquete.form.addEventListener( 'submit', event => {
            event.preventDefault();
            Enquete.bindSubmit();
        });
        Enquete.button.addEventListener( 'click', event => {
            event.preventDefault();
            Enquete.bindSubmit();
        });

        // Check voto
        Enquete.checkVoto();

    },
    
    captchaConfigure : function( data ){

        Enquete.captchaField = document.querySelector( 'input#recaptcha-enq' );
        Enquete.captchaBox = document.querySelector( '#recaptcha-enq-box' );
        
        if( Enquete.captchaField ){
        
            const recaptchaKey = Enquete.captchaField.dataset.recaptchaKey;

            grecaptcha.ready(function(){
        
                Enquete.recaptcha = grecaptcha.render(
                    Enquete.captchaBox, 
                    {
                        'sitekey' : recaptchaKey,
                        'size' : 'invisible',
                        'callback' : function( token ){
                            Enquete.captchaCallback( token );
                        }
                    }
                );
        
            });
        
        }

    },

    bindSubmit : async function(){

        Loader();
        grecaptcha.execute( Enquete.recaptcha );
    
    },

    // Captcha callback
    captchaCallback : function( token ){

        Loader( 'close' );

        // Insert captcha token
        Enquete.captchaField.value = token;            
        
        // Submit form
        Enquete.sendForm();
    
    },

    sendForm : async function(){

        Loader();

        const voto = Enquete.form.querySelector( 'input:checked' );

        $.ajax({
            url: "./submit-quizz.php",
            type : 'POST',
            dataType : 'JSON',
            data : {
                voto : voto.value,
                token : Enquete.captchaField.value
            },
            beforeSend: function( xhr ) {
                xhr.overrideMimeType( "text/plain; charset=utf-8" );
            }
        })
        .done( response => {
            Enquete.receiveRequest( response );            
        })
        .fail( ( jqXHR, textStatus, errorThrown ) => {
            console.info( "jqXHR" );
            console.log( jqXHR );
        })
        .always( () => {
            Loader( 'close' );
            console.log( "complete" );
        });

    },

    receiveRequest : function( response ){

        Enquete.quizz.classList.add( 'hide' );

        const
            title = Enquete.result.querySelector( 'h2' ),
            desc = Enquete.result.querySelector( 'em' ),
            button = Enquete.result.querySelector( 'button' ),
            anchor = Enquete.result.querySelector( 'a' )
        ;
        
        title.innerHTML = response.title;
        desc.innerHTML = response.desc;

        // CTAs
        if( response.anchor !== undefined ){
            button.remove();
            anchor.href = response.anchor;
            anchor.innerHTML = '<span>' + response.anchor_label + '</span>';
            anchor.setAttribute( 'aria-label', response.anchor_label );
        }
        else {
            anchor.remove();
        }

        // Styles
        if( response.style !== undefined ){
            Enquete.result.classList.add( 'style--' + response.style );
        }

        //Show
        Enquete.result.classList.add( 'show' );

        // Mark
        const mark = title.querySelector( 'mark' );
        if( mark ){
            setTimeout( () => {
                mark.classList.add( 'animated' );
            }, 200 );
        }
        
    },

    checkVoto : function(){

        const checked = Enquete.form.querySelector( 'input:checked' );
        
        if( checked !== null  ){
            Enquete.form.classList.add( 'active' );
            Enquete.button.disabled = false;
        }
        else {
            Enquete.form.classList.remove( 'active' );
            Enquete.button.disabled = true;
        }
        
    }

};

window.addEventListener( 'DOMContentLoaded', Enquete.init );
